import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții eficiente</span>
                            <h3>Despre managementul de proiect</h3>
                            <p>Managementul proiectelor de software implică planificarea, organizarea și controlul dezvoltării și întreținerii proiectelor de software. Managementul eficient al proiectelor de software este esențial pentru asigurarea faptului că proiectele de software sunt livrate la timp, în buget și cu o calitate înaltă. La Vertical Digital, avem o echipă de manageri de proiecte de software experimentați care vă pot ajuta organizația să gestioneze cu succes proiecte de software de orice dimensiune și complexitate.
                               </p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>Procesul nostru de management al proiectelor de software începe cu o evaluare amănunțită a nevoilor de management al proiectelor de software ale organizației dvs. Lucrăm cu dvs. pentru a vă defini obiectivele proiectului, a dezvolta un plan de proiect și a stabili roluri și responsabilități clare pentru toți membrii echipei.</p>
                                        <p>În timpul proiectului, oferim suport și orientare continuă pentru a asigura succesul proiectului de software. Acest lucru include monitorizarea progresului, identificarea și rezolvarea oricăror probleme care pot apărea și făcând orice ajustări necesare planului de proiect.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p>La Vertical Digital, suntem dedicați ajutorării organizațiilor să reușească cu proiectele lor de software. Echipa noastră de manageri de proiecte de software experimentați are abilitățile și expertiza necesare pentru a vă ghida organizația prin procesul de management al proiectelor de software, de la început până la sfârșit. Contactați-ne astăzi pentru a afla mai multe despre cum vă putem ajuta să livrați proiecte de software reușite la timp și în buget.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Jira</span></li>
                                <li><span>Confluence</span></li>
                                <li><span>Notion</span></li>
                                <li><span>Asana</span></li>
                                <li><span>Google Workspace</span></li>
                                <li><span>Redmine</span></li>
                                <li><span>Microsoft 365</span></li>
                                <li><span>Trello</span></li>
                                <li><span>Monday.com</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent